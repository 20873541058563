<template>
  <div
    class="fixed flex z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center"
  >
    <LoadingSpinner />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'GlobalLoading',
  components: { LoadingSpinner },
}
</script>
